<template lang="pug">
v-navigation-drawer(
  dark permanent
  :mini-variant="!expand"
  :mini-variant-width="miniVariantWidth"
  app
  :width="300"
  :src="`data:image/jpeg;base64,${barImg}`"
)
  template(v-slot:img="props")
    v-img(
      :gradient="`to top, ${barColor}`"
      v-bind="props"
    )
  v-list(
    nav
    style="-webkit-app-region: no-drag"
  )
    v-list-item(
      to="/"
      active-class="primary"
      :value="0"
    )
      v-list-item-icon
        v-icon mdi-home
      v-list-item-content
        v-list-item-title VDEMTECH
    v-divider.mb-2
    template(v-for="(item, index) in routes")
      v-list-group.cstm-slide-left(
        v-if="item.children"
        no-action
        active-class="white--text primary"
        :style="`animation-delay: ${index * 0.1}s`"
      )
        template(v-slot:activator)
          v-list-item-icon
            v-icon {{ item.icon }}
          v-list-item-title {{ item.title }}
        v-list-item(
          link
          v-for="(child, childIndex) in item.children" :key="childIndex"
          :value="(index * 10) + (childIndex + 1)"
          active-class="white--text yellow darken-4"
          :to="child.to"
        )
          v-list-item-avatar {{ child.title.substr(0, 1) }}
          v-list-item-title(v-text="child.title")
      v-list-item.cstm-slide-left(
        v-else
        :to="item.to"
        link
        :value="index + 1"
        active-class="primary"
        :style="`animation-delay: ${index * 0.1}s`"
      )
        v-list-item-icon
          v-icon(v-text="item.icon")
        v-list-item-title(v-text="item.title")
</template>
<script>
import VueRequestHandler from '@/libs/classes/VueRequestHandler.class'
import { requestVars } from '@/libs/api-helper.extra'
import authRepository from '@/repositories/auth.repository'
import { mapState } from 'vuex'
import appBarImgRepository from '@/repositories/app-bar-img.repository'

const [adminDataGetVars, adminDataGetVarNames] = requestVars({
  identifier: 'admin-data',
  data: 'adminData',
})
const adminDataGetHandler = new VueRequestHandler(null, adminDataGetVarNames)

const routes = () => [
  {
    accessLevel: 6,
    to: '/admin-controls',
    title: 'Admin Controls',
    icon: 'mdi-account-cog',
  },
  {
    accessLevel: 6,
    to: '/client-applications',
    title: 'VD Client Applications',
    icon: 'mdi-account-card',
  },
  {
    accessLevel: 5,
    to: '/employees',
    title: 'Employees',
    icon: 'mdi-account-hard-hat',
  },
  {
    accessLevel: 1,
    to: '/elevator-trouble-logs',
    title: 'Trouble Logs',
    icon: 'mdi-list-status',
  },
  {
    accessLevel: 1,
    to: '/preventive-maintenance',
    title: 'PM',
    icon: 'mdi-hammer-wrench',
  },
  {
    accessLevel: 5,
    to: '/customers',
    title: 'Customers',
    icon: 'mdi-account-supervisor',
  },
  {
    accessLevel: 5,
    to: '/stocks',
    title: 'Stocks',
    icon: 'mdi-clipboard',
  },
  {
    accessLevel: 5,
    to: '/suppliers',
    title: 'Supplier',
    icon: 'mdi-account-network',
  },
  {
    accessLevel: 5,
    to: '/purchase-requests',
    title: 'PR',
    icon: 'mdi-handshake',
  },
  {
    accessLevel: 5,
    to: '/customer-forecasts',
    title: 'Forecast',
    icon: 'mdi-poll',
  },
  {
    accessLevel: 5,
    to: '/incubators',
    title: 'Incubators',
    icon: 'mdi-duck',
  },
  {
    group: '/orders',
    title: 'Orders',
    to: '/orders',
    accessLevel: 5,
    icon: 'mdi-package-variant',
    // accessLevel: 5,
    // children: [
    //   { to: '/orders/default', title: 'Default', accessLevel: 5 },
    //   { to: '/orders/duck-eggs', title: 'Duck Egg', accessLevel: 6 },
    // ],
  },
  {
    accessLevel: 5,
    to: '/budget-codes',
    title: 'Budget Code',
    icon: 'mdi-cash-multiple',
  },
  {
    accessLevel: 5,
    to: '/project-codes',
    title: 'Project Codes',
    icon: 'mdi-hard-hat',
  },
  {
    group: '/',
    title: 'Accounting',
    icon: 'mdi-account-cash',
    accessLevel: 5,
    children: [
      { to: '/vouchers', title: 'Voucher', accessLevel: 5 },
      { to: '/reports', title: 'Reports', accessLevel: 6 },
    ],
  },
]

export default {
  name: 'Drawer',
  data() {
    return {
      expand: true,
      ...adminDataGetVars,
      barImg: null,
    }
  },
  created() {
    this.getAdminData()
    this.getBackgroundImg()
    this.expand = this.$vuetify.breakpoint.width >= 800
  },
  computed: {
    ...mapState({
      barColor: state => state.barColor,
    }),
    routes() {
      const defaults = routes()
      const { accessLevel = 0 } = this.$objectChangeKeyCase(this.adminData)
      return defaults
        .map(item => {
          if (item.children) {
            item.children = item.children.filter(subItem => subItem.accessLevel <= accessLevel)
          }
          return item
        })
        .filter(item => item.accessLevel <= accessLevel)
    },
    miniVariantWidth() {
      const breakpointName = this.$vuetify.breakpoint.name
      if (breakpointName !== 'xs' && breakpointName !== 'sm') return 56
      return 0
    },
  },
  watch: {
    // adminDataGetting (val) {
    //   if (!val) this.getAdminData()
    // },
    'adminData.name': function(val) {
      this.$store.dispatch('auth/setUser', val)
    },
    'adminData.access_level': function(val) {
      this.$store.dispatch('auth/setAccessLevel', val)
    },
  },
  methods: {
    getAdminData() {
      if (this.adminDataGetting) return
      const handler = adminDataGetHandler
      const params = this.getAdminDataParams()
      handler.setVue(this)
      handler.execute(authRepository.adminData, [params])
    },
    getAdminDataParams() {
      const { updatedAt = null } = this.$objectChangeKeyCase(this.adminData)
      return {
        updatedAt,
      }
    },
    getBackgroundImg() {
      appBarImgRepository
        .background('snow')
        .then(({ data }) => {
          this.barImg = data
        })
        .catch(e => {
          alert(e)
        })
    },
  },
}
</script>
